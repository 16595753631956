table.globalTable {
  width: 100%;
  font-size: 14px;
}


.globalTable thead {
  border-bottom: 1px solid black;
  font-weight: bold;
  padding: 0px;
  text-align: left;
  font-size: 14px;
}

.tableHead {
  height: 20px;
}

.tableHead tr {
  height: 30px;
  margin-bottom: 10px;
}


.tableBody {
  padding-top: 10px;
}

.globalTable tbody {
  padding-top: 10px;
}

.globalTable tbody tr {
  height: 35px;
  padding: 1px 0px;
  width: 100%;
  color: #333;
}

.globalTable tbody td {
  text-align: left;
  font-weight: normal;
  height: 100%;
  margin-bottom: 1px;
  padding: 3px 5px;

  &:first-of-type {
    padding-left: 0px;
  }

  &:last-of-type {
    padding-right: 0px;
  }
}