/* TARGET */
.targetsWrapper {
  margin: auto 0px;
  display: flex;
  flex-direction: column;
}

.targetList {
  display: grid;
  width: 100%;
  /* grid-auto-flow: column; */
  grid-template-columns: repeat(3, minmax(60px, 100px));
  margin: 0 auto;
  gap: 5px;
}

.targetList3 {
  grid-template-columns: repeat(3, minmax(60px, 33%));
}

.targetList4 {
  grid-template-columns: repeat(4, minmax(60px, 25%));
}

.targetList5 {
  grid-template-columns: repeat(5, minmax(60px, 20%));
}


.target {
  margin-top: 15px;
  height: 50px;
  width: inherit;
  list-style: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  color: black;
  font-size: 18px;

  /* &.active .targetBar {
    outline: 2px solid;
  } */

}

.targetText {
  width: inherit;
  border: 1px solid blue;
}

.targetBar {
  height: 10px;
  width: 100%;
  margin-top: 10px;
  position: relative;

}

.targetBackground {
  opacity: 0.5;
  width: inherit;
  height: inherit;
  border: 2px solid gray;
}

.targetFill {
  background: black;
  height: inherit;
  width: inherit;
  transition: width 200ms;
  position: absolute;
  z-index: -1;
  opacity: 1;

}

.target0 {
  background: red;
  /* TARGET BACKGROUND */
  border: 1px solid red;
}

.target1 {
  background: green;
  /* TARGET BACKGROUND */
  border: 1px solid green;
}

.target2 {
  background: blue;
  /* TARGET BACKGROUND */
  border: 1px solid blue;
}

.target3 {
  background: yellow;
  /* TARGET BACKGROUND */
  border: 1px solid yellow;
  color: black;
}

.target4 {
  background: purple;
  /* TARGET BACKGROUND */
  border: 1px solid purple;
}

.target5 {
  background: black;
  /* TARGET BACKGROUND */
  border: 1px solid black;
}

.target6 {
  background: maroon;
  /* TARGET BACKGROUND */
  border: 1px solid maroon;
}

.target7 {
  background: teal;
  /* TARGET BACKGROUND */
  border: 1px solid teal;
}

.target8 {
  background: navy;
  /* TARGET BACKGROUND */
  border: 1px solid navy;
}

.targetText {
  background: transparent;
  border-color: transparent;
  width: 100%;
}