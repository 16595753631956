.panel {
  display: block;
  height: inherit;
  border-right: 1px solid black;
  text-align: center;
  padding: 20px;
  width: min-content;
  z-index: 1;
  overflow: scroll;
  max-height: 100vh;

  /* MOBILE Rules */
  @media only screen and (max-width: 1000px) {
    height: auto;
    max-height: 80vh;
    width: 100%;
  }
}