/* CELLS */
.board {
  display: grid;
  grid-template-columns: repeat(3, minmax(60px, 100px));
  grid-template-rows: repeat(3, minmax(60px, 100px));
  margin: 0 auto;
  width: 100%;
  gap: 5px;
}

.board3 {
  width: 100%;
  grid-template-columns: repeat(3, minmax(60px, 33%));
  grid-template-rows: repeat(3, minmax(60px, 33%));
}

.board4 {
  width: 100%;
  grid-template-columns: repeat(4, minmax(60px, 25%));
  grid-template-rows: repeat(4, minmax(60px, 25%));
}

.board5 {
  width: 100%;
  grid-template-columns: repeat(5, minmax(60px, 20%));
  grid-template-rows: repeat(5, minmax(60px, 20%));
}

.cell {
  width: 100%;
  color: black;
  border: 1px solid #BCBCBC;
  border-radius: 4px;
  opacity: 0.5;
  background: lightgray;
  height: inherit;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  code {
    font-size: 10px;
  }
}

.clickable {
  background: white;
  cursor: pointer;
  opacity: 1;
  box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.15);
  transition: box-shadow 300ms, color 300ms, background-color 300ms;

  &:hover {
    box-shadow: none;
  }
}

.pressed {
  box-shadow: inset 1px 3px 2px rgba(0, 0, 0, 0.15);
  border: none;
  color: white;
}


.cellValue {
  font-size: 18px;
}