.clickable {
  cursor: pointer;
}

.mineralBadge {
  width: 12px;
}

.mineralBadgeCell {
  display: flex;
}