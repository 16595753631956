.numberGameWrapper {
  height: 90vh;
  display: flex;
  max-width: 90%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

.numberGameContent {
  width: 95%;
  max-width: 400px;
  margin: 0 auto;
}

.gameWrapper {
  width: 100%;
  max-width: inherit;
  max-height: 80vh;
  margin: 0 auto;
}

.boardWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: inherit;
  margin: 6px auto 15px;
}