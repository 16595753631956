.pageWrapper {
  background: #8ED4FF;

}

.pageContainer {
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 50px auto;
  height: 100vh;
  background: white;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
}