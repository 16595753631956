.title {
  display: flex;
  align-items: center;
  padding: 0px 0px 5px;
  font-weight: bold;
  font-size: 26px;
  width: 100%;
  height: 30px;
}

.subTitle {
  display: flex;
  align-items: center;
  padding: 0px 0px 5px;
  font-weight: bold;
  font-size: 20px;
  width: 100%;
}