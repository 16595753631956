.container {
  padding: 15px;
  border: 1px solid #707070;
  margin-bottom: 15px;
  min-width: 250px;
  width: auto;

  .container {
    padding: 10px;
    margin: 10px 0px;
  }
}


[role="tabpanel"]>.container {
  border-top: 0px;
}