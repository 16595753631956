.modalBackground {
  width: 100%;
  z-index: 888;
  height: 100vh;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalForeground {
  width: 500px;
  z-index: 999;
  background: white;
  border: 3px solid black;
  padding: 0px 15px 15px;

  button {
    margin-right: 10px;
    background: black;
    color: white;
    padding: 5px;
    border: none;
    cursor: pointer;
    border: 1px solid black;

    ::hover {
      color: black;
      background: white;
      border: 1px solid black;
    }
  }
}