.details {
  color: gray;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin: 10px 0px;
}

.badge {
  width: 40px;
  margin-right: 10px;
  height: 100%;
}