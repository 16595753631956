.container {
  border: 1px solid black;
  padding: 10px 30px;
  /* width: 42%; */
  height: 100px;
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 1200px) {
    padding: 10px 20px;
    height: auto;
  }
}


.container:hover {
  background: black;
  color: white;
}