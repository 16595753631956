.Land {
  text-align: center;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

table {
  border-collapse: collapse;
  height: fit-content;
  width: auto;
}

td,
tr {
  border: none;
}

tr {
  height: 60px;
}


.selected {
  outline: 4px solid black;
}

.spaceBetween {
  display: flex;
  width: inherit;
  justify-content: space-between;
}

.dim {
  color: gray;
}

.hideMobile {
  height: inherit;
  display: flex;

  small {
    color: blue;
  }

  /* MOBILE Rules */
  @media only screen and (max-width: 1000px) {
    display: none;
  }
}

.hideDesktop {
  display: none;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 1000px) {
    height: inherit;
    display: flex;

    small {
      color: red;
    }
  }
}

.welcome {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcomeContent {
  width: 70%;
  max-width: 500px;
  border: 1px solid black;
  padding: 20px
}