.splashWrapper {
  margin: 10px auto;
  max-width: 1200px;
  /* width: 100%; */

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 1200px) {
    padding: 0 10px;
    max-width: 800px;
    width: 90%;
  }

}

.splashHeader {
  text-align: center;
}

.purple {
  color: SlateBlue;
}

.gameList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  padding: auto;
  justify-content: space-between;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    height: auto;
  }
}

h1 {
  font-size: 70px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0px;
  margin: 10px 0 20px;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 1200px) {
    font-size: 30px;
  }
}

.noGames {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 60vh;
}