.parcel {
  min-width: 80px;
  width: auto;
  min-height: 80px;
  height: auto;
  border: 1px solid black;
  text-align: center;
  background: white;
  margin: 3px;
  cursor: pointer;

  .selected {
    outline: 6px solid black;
  }
}

.locked {
  background: darkslategray;
  color: white;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.parcel:hover {
  outline: 4px solid black;
}

.parcelBody {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.statBars {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.statProgress {
  height: inherit;
  font-size: 10px;
}

.statPercent {
  font-size: 10px;
  padding-left: 3px;
  position: relative;
}

.mineralBadge {
  display: flex;
  height: inherit;
  align-items: center;
}

.cropBadge {
  width: 100%;
  min-width: 20px;
  height: 70%;
  margin-bottom: 2px solid white;
  display: flex;
  justify-content: space-around;
}

.cropPercent {
  font-size: 10px;
}

.parcelToolTip {
  display: block;
}

.parcelToolTip {
  display: none;
  background: white;
  border: 1px solid black;
  position: sticky;
  z-index: 99;
  padding: 3px;
  width: max-content;
}

.parcelId {
  display: flex;
}