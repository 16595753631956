.bank {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;

  p {
    margin: 0px;
  }
}

.label {
  font-weight: bold;
}