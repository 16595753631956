.TabBar {
  display: flex;
  width: 100%;
}

.tab {
  border: 1px solid #707070;
  flex: 1;
  background: #333;
  color: white;
  padding: 5px 0px;
  cursor: pointer;
  border-right: 1px solid black;

  &:disabled {
    background: lightgray;
    cursor: default;
  }
}

.activeTab {
  background: white;
  color: black;
  border-bottom: none;
  font-weight: bold;
}

.tabPanel {
  display: none;
}

.activeTabPanel {
  display: block;
}