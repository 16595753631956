.field {
  width: 99%;
  height: 100vh;
  padding: 6px;
  overflow: scroll;
  background: #c3c3c3;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-template-rows: repeat(auto-fill, 80px);
  gap: 6px;
  box-shadow: inset 3px 3px 4px 1px rgba(0, 0, 0, 0.3);

  /* MOBILE Rules */
  @media only screen and (max-width: 1000px) {
    flex: 1;
    width: calc(100% - 12px);
    box-shadow: none;
  }
}