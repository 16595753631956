.spaceBetween {
  display: flex;
  width: inherit;
  justify-content: space-between;
}

.dim {
  color: gray;
}

.cropContainer {
  width: 100%;
}