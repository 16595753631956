/* SETTINGS */
.settings {
  padding: 0px;

  p {
    margin: 0px;
  }

  & .settingsRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  & .resetBtn {
    background: #FF7600;
    color: white;
  }

  & .devControlsBtn {
    background: lightsalmon;
    color: black;
  }

  & .sizings {
    display: flex;
  }




}

.settings .sizeBtn {
  margin-right: 5px;
  border: 1px solid #BCBCBC;
  color: black;
  transition: box-shadow 300ms;

  &.active {
    outline: none;
    background: #3B3B3B;
    color: white;
    box-shadow: none;
  }

}

.settings button {
  margin: 5px 0px;
  background: none;
  cursor: pointer;
  border: none;
  padding: 3px 10px;
  border-radius: 4px;
  color: black;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);

  &:hover {
    box-shadow: none;
  }
}