.clueContainer {
  border: 1px solid black;
  padding: 10px 30px;
  transition: all 300ms;
  margin: 20px auto;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  max-width: 700px;
}

.clueTitle {
  padding: 0;
}

.clueQuestion {
  padding: 0;
}

.clueId {
  padding: 0;
  color: lightgray;
}

.questionRow {
  display: flex;
  justify-content: space-between;
}

.active {
  background: white;
  color: black;
}

.locked {
  opacity: 0.2;
}

.correct {
  opacity: 0.2;
}

.incorrect {
  border: 1px solid red;
}

.form {
  display: flex;

  input {
    width: 70%;
    margin-right: 10px;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid #333;
  }

}

.submitButton {
  border: none;
  background: #1CA9FF;
  color: white;
  padding: 5px;
  width: 100px;
  border-radius: 5px;
}