.button {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #333;
  color: white;
  margin: 15px 0px;
  cursor: pointer;
  border: 0;
  font-weight: bold;
}

.button:hover {
  background: black;
}