.cropBadge {
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  font-size: 16px;
}

.corn {
  background: gold;
  color: black;
}

.cotton {
  background: WhiteSmoke;
  color: black;
  outline: 1px solid #dbdbdb;
}

.apples {
  background: darkred;
  color: white;
}

.soybeans {
  background: lightgreen;
  color: black;
}

.potatoes {
  background: Peru;
  color: white;
}

.tomatoes {
  background: Tomato;
  color: black;
}

.sorghum {
  background: Chocolate;
  color: black;
}

.grapes {
  background: Indigo;
  color: white;
}

.oranges {
  background: orange;
  color: black;
}

.lettuce {
  background: forestgreen;
  color: black;
}

.sugar {
  background: ivory;
  color: black;
  outline: 1px solid #efefef;
}

.wheat {
  background: wheat;
  color: black;
}

.hay {
  background: Tan;
  color: black;
}

.almonds {
  background: Sienna;
  color: white;
}

.strawberries {
  background: Crimson;
  color: white;
}

.rice {
  background: GhostWhite;
  color: black;
  outline: 1px solid #b0b0b0;
}

.pistachios {
  background: PaleGreen;
  color: black;
}

.peanuts {
  background: SaddleBrown;
  color: white;
}

.beets {
  background: MediumVioletRed;
  color: white;
}

.beans {
  background: Cornsilk;
  color: black;
}

.blueberries {
  background: DarkBlue;
  color: white;
}

.squash {
  background: SandyBrown;
  color: black;
}