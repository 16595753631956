.mineralBadge {
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carbon {
  background: black;
  color: white;
}

.hydrogen {
  background: blue;
  color: white;
}

.oxygen {
  background: lightblue;
  color: black;
}

.phosphorus {
  background: ivory;
  color: black;
}

.potassium {
  background: goldenrod;
  color: black;
}

.nitrogen {
  background: yellow;
  color: black;
}

.sulfur {
  background: pink;
  color: black;
}

.calcium {
  background: seashell;
  color: black;
}

.iron {
  background: red;
  color: white;
}

.magnesium {
  background: grey;
  color: white;
}

.boron {
  background: purple;
  color: white;
}

.manganese {
  background: green;
  color: black;
}

.copper {
  background: Sienna;
  color: white;
}

.zinc {
  background: maroon;
  color: white;
}

.molybdenum {
  background: dimgrey;
  color: white;
}

.chlorine {
  background: limegreen;
  color: black;
}