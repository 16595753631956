.a {
  background: red;
  color: white;
}

.b {
  background: blue;
  color: white;
}

.c {
  background: yellow;
  color: black;
}

.d {
  background: green;
  color: white;
}

.e {
  background: purple;
  color: white;
}

.x {
  background: brown;
  color: white;
}


.tree {
  background: green;
  color: white;
}

.rock {
  background: grey;
  color: white;
}

.ground {
  background: saddlebrown;
  color: white;
}

.mushroomYellow {
  background: yellow;
  color: black;
  font-size: 28px;
}

.lilyPurple {
  background: purple;
  color: white;
}

.lilyBlue {
  background: blue;
  color: white;
}

.page {
  background: midnightblue;
  height: 100vh;
}

.pageWrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  color: white;

}

.gameHeader {
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* MOBILE Rules */
  @media only screen and (max-width: 1000px) {
    padding: 10px;
  }

  & h1 {
    text-transform: none;
    justify-self: flex-start;
    font-size: 36px;
    margin: 0px;
    padding: 0px;

    /* MOBILE Rules */
    @media only screen and (max-width: 1000px) {
      font-size: 24px;
    }
  }
}


.cell {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  small {
    font-size: 4px;
  }
}

.rightEdge {}

.leftEdge {}

.topEdge {}

.bottomEdge {}

.playField {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  /* 20 (cell hight and width) * 60 (up/down key change value) */
  background: saddlebrown;
  border: 5px solid black;

  &.rightEdge {
    border-right-color: blue;
    border-right-width: 5px;
  }

  &.leftEdge {
    border-left-color: blue;
    border-left-width: 5px;
  }

  &.topEdge {
    border-top-color: blue;
    border-top-width: 5px;
  }

  &.bottomEdge {
    border-bottom-color: blue;
    border-bottom-width: 5px;
  }
}

.standing {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  40% {
    background: black;
  }

  60% {
    background: black;
  }
}

.hud {
  margin: 10px auto;
  /* width: inherit; */
  border: 1px solid black;
  padding: 10px;
  background: #444;

  & h4 {
    margin: 0px;
    margin-top: 5px;
  }
}

.statsBody {
  padding-bottom: 5px;
  border-bottom: 1px solid dimgray;
  font-size: 8px;
  display: flex;
  justify-content: space-between;
}

.inventory {
  padding: 10px 5px;
  width: inherit;

  &:focus {
    border: none;
  }
}

.inventoryItem {
  display: flex;
}

.controls {
  color: white;
  width: 80%;
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & button {
    height: 40px;
    width: 70px;
    color: black;
    background: white;
    box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    border: none;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;

    &:active {
      background: gray;
    }
  }
}

.dPad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);


  &>button {
    height: 40px;
    width: 70px;
    color: black;
    background: white;
    /* background: rgba(0, 0, 0, 0.8); */
    box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    border: none;
    font-weight: bold;
    font-size: 30px;

    &:active {
      background: gray;
    }
  }
}

.pickUpBtn {
  margin-top: 20px;
  height: 40px;
  width: 100px;
}