.boardWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: inherit;
  height: inherit;
  max-width: 500px;
  margin: 6px auto 15px;
}

.boardStats {
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;

  p {
    margin: 0px;
  }
}

.boardStatsDate {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    background: none;
    cursor: pointer;
    color: black;

  }
}

.dayNavBtn {
  border: none;
  color: black;
  background: none;
}

.boardShare {
  width: 100%;
  margin: 5px 0px 10px;
  padding: 12px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid goldenrod;
  border-radius: 4px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
  background: gold;
}