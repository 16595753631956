.sidebar {
  bottom: 0px;
  position: fixed;
  overflow: visible;
  background: white;
  width: 100%;
  min-height: max-content;
  flex: 1;
  box-shadow: 3px -3px 4px 1px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.expandSidebar {
  flex: 1;
}